import { template as template_69e73af4d485496896de6dce88cc2a69 } from "@ember/template-compiler";
const WelcomeHeader = template_69e73af4d485496896de6dce88cc2a69(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
