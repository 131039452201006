import { template as template_09655c15a5024a22a4f8e4a2adc98b21 } from "@ember/template-compiler";
const SidebarSectionMessage = template_09655c15a5024a22a4f8e4a2adc98b21(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
